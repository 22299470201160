.custom-dropdown-menu {
  position: absolute;
  top: calc(var(--yq-navbar-height) - 12px);
  left: calc(50% - 120px);
  z-index: 1200;
  width: 240px;
  max-height: 360px;
  display: none;
  // margin-top: 2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  overflow-y: overlay;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid color(display-p3 0.9333 0.9333 0.9333);
  border-radius: 12px;
  box-shadow:
    0 29px 8px 0 color(display-p3 0 0 0 / 0),
    0 18px 7px 0 color(display-p3 0 0 0 / 0),
    0 10px 6px 0 color(display-p3 0 0 0 / 0.02),
    0 5px 5px 0 color(display-p3 0 0 0 / 0.03),
    0 1px 3px 0 color(display-p3 0 0 0 / 0.03);
  transform-origin: right top;
  animation-duration: 0.3s;

  &.open {
    display: block;
    background-color: #fff !important;
  }
}

.list-group-menu {
  a {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 44px;
    color: var(--text-color-primary);
    margin: 0;
    padding: 8px 12px;
    margin: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    white-space: nowrap;
    border: none;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: #eee;
    }
  }
}

.divider {
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}

@media screen and (max-width: 767px) {
  .custom-dropdown-menu {
    right: 0;
    left: 0;
    top: 100%;
    width: auto;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
