// 用于非tab切换的效果
@keyframes hoverMove {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

// 用于切换不同tab的交互
@keyframes hoverTabMove {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0.2;
  }

  70% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
}

// 菜单导航item
.nav-item {
  padding: 0;
  // height: var(--yq-navbar-height);
  font-weight: 600;
  display: flex;
  align-items: center;

  .dropdown-item {
    & + .dropdown-item {
      margin-top: 32px;
    }
  }

  p {
    margin: 0;
  }

  .item-toggle-label {
    padding: 24px 8px 24px 16px;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      width: 0%;
      height: 2px;
      background-color: var(--primary-color-hover);
      position: absolute;
      left: 50%;
      bottom: 0;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      transition: 0.2s;
      transition:
        width 0.3s ease,
        left 0.3s ease;
    }
  }
  .font-head {
    margin-bottom: 4px;
  }

  .dropdown-menu-custom {
    display: none;
    background-color: #fff;
    box-shadow:
      0 0 8px rgba(0, 0, 0, 0.03),
      0 3px 8px rgba(0, 0, 0, 0.04),
      0 8px 20px rgba(0, 0, 0, 0.06);
    clip-path: inset(0 0 -40px 0);
    left: 0;
    overflow: hidden;
    position: absolute;
    top: var(--yq-navbar-height);
    transform-origin: top;
    width: 100%;
    width: 100%;
    will-change: opacity, transform;
    z-index: 100;
    * {
      transform: translateY(-4px);
      transition-property: opacity, transform;
      transition-duration: 0.42s;
      transition-delay: calc(400ms);
    }
  }
  &.nav-item-tab-open {
    color: var(--primary-color-hover);
    .item-toggle-label {
      &::after {
        content: '';
        left: 0;
        width: 100%;
      }
    }
    .dropdown-menu-custom {
      opacity: 1;
      display: block;
      * {
        will-change: opacity, transform;
        animation: hoverTabMove 0.12s ease-in-out;
        transform: translateY(0);
      }
    }
  }
  &.nav-item-open {
    color: var(--primary-color-hover);
    .item-toggle-label {
      &::after {
        content: '';
        left: 0;
        width: 100%;
      }
    }
    .dropdown-menu-custom {
      will-change: opacity, transform;
      animation: hoverMove 0.2s ease-in-out;
      display: block;
      * {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

// 下拉菜单布局
.dropdown-menu-custom {
  .dropdown-menu-custom-content {
    margin: 0 auto;
    max-width: 1440px;
    padding: 40px 20px;
    justify-content: space-between;
    .imgBox {
      will-change: opacity, transform;
      opacity: 0;
      display: none;
      &.img-item-open {
        animation: hoverTabMove 0.2s ease-in-out;
        opacity: 1;
        display: block;
        // transform: translateY(0);
        * {
          opacity: 1;
          // transform: translateY(0);
        }
      }
    }
  }
  .submenu {
    h4 {
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
      color: #616161;
      font-weight: 400;
      line-height: 20px;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        // margin-bottom: 5px;
      }
    }
  }
}

// 通用样式类

.nav-item {
  // 头部内容通用
  .font-head {
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.2px;
    margin-bottom: 4px;
  }

  // 描述内容通用
  .font-desc {
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
  }
  // 右箭头
  .arrowRight {
    display: flex;
    align-items: center;
    .arrow {
      display: inline-block;
      opacity: 0 !important;
      padding-left: 4px;
      transform: translateX(0px) !important;
      transition: 0.2s !important;
    }
    &:hover {
      color: var(--primary-color-hover);
      .arrow {
        transform: translateX(4px) !important;
        opacity: 1 !important;
      }
    }
  }

  // 左侧内容设计稿单独设置样式
  .flex-left-container {
    flex: 1;
    max-width: 420px;
    padding: 0;
    @media (max-width: 1199px) {
      max-width: 100%;
    }
  }

  .flex-right-img-container {
    max-width: 800px;
    padding: 0;
    .item-img-box {
      p,
      a {
        margin-top: 24px;
      }
    }
    @media (max-width: 1199px) {
      max-width: 100%;
      display: none;
    }
  }
}
@media (min-width: 1240px) and (max-width: 1439px) {
  .dropdown-menu-custom {
    .dropdown-menu-custom-content {
      padding: 40px 50px;
    }
  }
}

// 移动端样式
@media (max-width: 1199px) {
  .nav-item {
    height: auto;
    width: 100%;
    flex-direction: column;
    text-align: left;
    .item-toggle-label {
      width: 100%;
      padding: 16px 24px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }

    & > a {
      padding: 16px 24px;
    }

    .btn-link {
      padding: 16px 24px;
      font-size: 20px;
    }

    &.nav-item-tab-open {
      color: unset;
      .item-toggle-label {
        &::after {
          display: none;
        }
      }
      .dropdown-menu-custom {
        opacity: 1;
        display: block;
        * {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    &.nav-item-open {
      color: unset;
      .item-toggle-label {
        &::after {
          display: none;
        }
      }
    }
    .dropdown-menu-custom {
      animation: hoverMove 0.2s ease-in-out;
      position: relative;
      top: 0;
      box-shadow: none;
      .dropdown-menu-custom-content {
        background: color(display-p3 0.9804 0.9804 0.9804);
        padding: 24px 40px;
        flex-direction: column;
        justify-content: space-between;
        gap: 0px;
        .submenu {
          :not(:last-child) {
            padding-bottom: 4px;
          }
        }
      }
    }
  }

  .font-head {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .xsStatic {
    position: static !important;
  }
}

@media (max-width: 390px) {
  .xsStatic {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .md-flex-show {
    display: flex !important;
    width: 100%;
  }

  .md-flex-show {
    display: none !important;
  }
}
