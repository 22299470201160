.drawer {
  position: fixed;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
}

.drawer.bottom {
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(100%);
  border-radius: 16px 16px 0 0;
}

.drawer.top {
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-100%);
  border-radius: 0 0 16px 16px;
}

.drawer.left {
  left: 0;
  top: 0;
  height: 100%;
  transform: translateX(-100%);
  border-radius: 0 16px 16px 0;
}

.drawer.right {
  right: 0;
  top: 0;
  height: 100%;
  transform: translateX(100%);
  border-radius: 16px 0 0 16px;
}

.drawer.open {
  transform: translate(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
