.navbar-right {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  .m-size-3 {
    margin: 0 16px 0 0;
  }
}

// 通用样式类

// 通用按钮
.btn {
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  border-radius: 12px;
  padding: 8px 16px;
  text-decoration: none;
}

// 登录按钮
.btn-login {
  color: var(--primary-color);
  background-color: transparent;
  border: none;

  &:hover,
  &:focus,
  &:active {
    color: var(--primary-color-hover) !important;
    background-color: transparent !important;
  }
}

// 语言下拉按钮
.btn-language {
  padding: 12px 0;
  font-weight: 600;
}

// 注册按钮
.btn-register {
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  &:hover {
    color: #fff;
    background-color: var(--primary-color-hover);
  }
}

// link格式按钮
.btn-link {
  display: inline-block;
  width: 100%;
  padding: 24px 8px 24px 16px;
  color: #212121;
  text-decoration: none;
  &:hover {
    color: #003a9b;
    text-decoration: none;
  }
}

// shopify按钮
.installShopifyBox {
  .installShopify {
    color: #fff;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 12px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    background: #008060;
    gap: 4px;
    &:hover {
      background: #41a078;
      color: #fff;
    }
  }
  @media (max-width: 1199px) {
    margin: 0 16px 0 0;
  }
}

// 消息通知按钮
.messageButton {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid color(display-p3 0.9333 0.9333 0.9333);
  background: color(display-p3 0.9804 0.9804 0.9804);
  .messageNum {
    display: flex;
    width: 18px;
    font-size: 14px;
    height: 18px;
    padding: 10px;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(1px, -4px);
    right: 0;
    top: 0;
    border-radius: 10px;
    border: 1px solid color(display-p3 1 1 1);
    background: color(display-p3 0.8235 0.2941 0.2549);
  }
}

// 用户按钮
.userButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

// 注册按钮盒子
.register-box {
  .registerDrawerBox {
    .line {
      width: 40px;
      height: 4px;
      flex-shrink: 0;
      border-radius: 11px;
      background: color(display-p3 0 0 0);
      margin: 8px auto;
    }
    .btn-register-drawerList {
      display: flex;
      justify-content: space-between;
      padding: 18px 16px;
      color: #212121;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.2px;
    }
  }
}

.open > a,
.open > button {
  color: #616161;
  background-color: rgba(238, 238, 238, 0.6);
}

.avatar {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  vertical-align: bottom;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  border-radius: 20px;
  border: 1px solid var(--Border-Primary, color(display-p3 0.9333 0.9333 0.9333));
}

.avatar-online i {
  background-color: #4caf50;
}

.avatar i {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 100%;
}
// 移动端样式

@media (min-width: 1240px) and (max-width: 1439px) {
  .navbar-container {
    padding: 0 50px;
  }
}
@media (max-width: 1199px) {
  .expanded {
    // 会根据下拉控件展示移动端菜单
    &.navbar-right {
      flex-direction: column;
      text-align: center;
    }

    &.navbar-right {
      margin: 0 16px 32px 16px;
    }

    .dropdown-md-hidden {
      display: none;
    }

    .dropdown-mobile-flex-show {
      display: flex !important;
      width: 100%;
      & > div {
        width: 100%;
      }
      .btn {
        width: 100%;
      }
      .btn-login {
        border: none;
      }
    }
  }
}

@media (max-width: 768px) {
  // 下拉折叠按钮
  .hamburger_box {
    margin-left: 16px;
  }

  .expanded {
    .dropdown-xs-flex-show {
      display: flex !important;
      width: 100%;
      & > div {
        width: 100%;
      }
      .btn {
        width: 100%;
      }
      .btn-login {
        border: none;
      }
    }
    &.navbar-right {
      .m-size-3 {
        margin: 0 0 16px 0;
      }
      .loginBtnBox {
        flex-direction: column;
        gap: 16px;
      }
      .installShopifyBox {
        margin: 0;
      }
    }
  }

  .xsStatic {
    position: static !important;
  }
}

@media (max-width: 390px) {
  .xsStatic {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .md-flex-show {
    display: flex !important;
    width: 100%;
  }
  .expanded {
    .md-flex-show {
      display: none !important;
    }
    .dropdown-md-flex-show {
      display: flex !important;
      width: 100%;
    }
  }
}
