@keyframes fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: translate(50%, 0) scale(0.2);
    -o-transform: translate(50%, 0) scale(0.2);
    transform: translate(50%, 0) scale(0.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(50%, 0) scale(1);
    -o-transform: translate(50%, 0) scale(1);
    transform: translate(50%, 0) scale(1);
  }
}
.custom-dropdown-menu {
  position: absolute;
  top: calc(var(--yq-navbar-height) - 16px);
  right: 50%;
  transform: translate(50%, 0);
  z-index: 1200;
  min-width: 220px;
  width: auto;
  padding: 8px;
  max-height: 360px;
  display: none;
  font-size: 14px;
  text-align: left;
  list-style: none;
  overflow-y: overlay;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid color(display-p3 0.9333 0.9333 0.9333);
  border-radius: 12px;
  box-shadow:
    0 29px 8px 0 color(display-p3 0 0 0 / 0),
    0 18px 7px 0 color(display-p3 0 0 0 / 0),
    0 10px 6px 0 color(display-p3 0 0 0 / 0.02),
    0 5px 5px 0 color(display-p3 0 0 0 / 0.03),
    0 1px 3px 0 color(display-p3 0 0 0 / 0.03);
  transform-origin: right top;
  animation-duration: 0.3s;

  &.open {
    display: inline-table;
    background-color: #fff !important;
    animation-name: fade-scale-02;
  }
}

.list-group-menu {
  display: flex; /* 让item使用flex布局 */
  justify-content: space-between; /* 让a和svg在item中左右对齐 */
  width: 100%; /* 让item宽度撑满box */
  align-items: center;
  border: none;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: #f5f5f5;
  }
  a {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 44px;
    color: var(--text-color-primary);
    padding: 14px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    white-space: nowrap;
    border: none;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
  }
}

.divider {
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}

@media screen and (max-width: 767px) {
  .custom-dropdown-menu {
    right: 0;
    left: 0;
    width: auto;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
