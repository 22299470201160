.custom-dropdown-menu {
  position: absolute;
  top: calc(var(--yq-navbar-height) - 50%);
  left: calc(100% - 180px);
  z-index: 1200;
  width: 360px;
  max-height: 360px;
  display: none;
  margin-top: 5px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  transform-origin: right top;
  animation-duration: 0.3s;
  border-radius: 12px;
  border: 1px solid color(display-p3 0.9333 0.9333 0.9333);
  background: color(display-p3 1 1 1);
  box-shadow:
    0 29px 8px 0 color(display-p3 0 0 0 / 0),
    0 18px 7px 0 color(display-p3 0 0 0 / 0),
    0 10px 6px 0 color(display-p3 0 0 0 / 0.02),
    0 5px 5px 0 color(display-p3 0 0 0 / 0.03),
    0 1px 3px 0 color(display-p3 0 0 0 / 0.03);

  &.open {
    display: block;
    background-color: #fff !important;
  }
}

.dropdown-user-info {
  width: 360px;
  padding: 0;

  .dropdown-menu-header {
    position: relative;
    padding: 16px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 12px 12px 0 0;
    .user-avatar {
      display: block;
      width: 48px !important;
      height: 48px !important;
      background-color: #fff;
      background-position: center center;
      background-size: 100% 100%;
      border-radius: 50%;
    }

    .email {
      margin: 4px 0 0;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: stretch;
      color: #616161;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.1px;
    }

    .name {
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #212121;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.03px;
    }

    .buyer-area {
      display: flex;
      align-items: center;
      margin: 0;
    }

    .buyer-vip {
      margin-left: 4px;
    }

    .vip-tag {
      color: var(--secondary-color);
    }

    .buyer-btn {
      color: var(--primary-color);
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.1px;
    }

    .buyer-center,
    .seller-center {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding: 0 15px;
      margin-top: 15px;
      color: #fff;
      background-color: #ff8c00;
      border-radius: 8px;
      text-decoration: none;

      &:hover {
        background-color: var(--secondary-color-hover);
      }
    }
  }

  .dropdown-menu-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border-radius: 0 0 12px 12px;
    a {
      color: #9e9e9e;
      padding: 16px;
    }

    .account {
      color: #212121;
      display: flex;
      align-items: center;
    }

    .exit {
      color: #616161;
      &:hover {
        color: #003a9b;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .custom-dropdown-menu {
    right: 0;
    left: 0;
    top: 100%;
    width: auto;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
