.custom-dropdown-menu {
  position: absolute;
  top: calc(var(--yq-navbar-height) - 50%);
  left: calc(100% - 180px);
  z-index: 1200;
  width: 320px;
  max-height: 360px;
  display: none;
  padding: 5px 0;
  margin-top: 5px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  overflow-y: overlay;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow:
    0 29px 8px 0 color(display-p3 0 0 0 / 0),
    0 18px 7px 0 color(display-p3 0 0 0 / 0),
    0 10px 6px 0 color(display-p3 0 0 0 / 0.02),
    0 5px 5px 0 color(display-p3 0 0 0 / 0.03),
    0 1px 3px 0 color(display-p3 0 0 0 / 0.03);
  transform-origin: right top;
  animation-duration: 0.3s;

  &.open {
    display: block;
    background-color: #fff !important;
  }
}

.dropdown-message {
  width: 360px;
  padding: 0;
  max-height: 500px;
  overflow: hidden;

  .dropdown-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #212121;
    position: relative;
    padding: 13px 20px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;

    button {
      color: #616161;
      height: 36px;
      line-height: 36px;
      text-align: center;
      padding: 0 15px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .msg-list {
    height: 270px;

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :global {
      .spinner-border {
        width: 45px;
        height: 45px;
      }
    }
  }

  .msg-item {
    padding: 15px 20px;

    &:not(:first-child) {
      border-top: 1px solid #e0e0e0;
    }

    &:hover {
      background-color: #eee;
    }

    .circle {
      position: relative;
      font-size: 12px;
      width: 24px;
      height: 24px;
      background-color: #eee;
      border-radius: 50%;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .unread-msg {
        color: #ff8c00;
      }
    }

    h6 {
      color: #212121;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .time {
      color: #757575;
      font-size: 12px;
    }
  }

  .reminder {
    font-size: 14px;
    color: #212121;
    text-align: center;
    padding: 7px 20px;
    border-top: 1px solid #e0e0e0;

    span {
      color: #ff8c00;
    }
  }

  .empty-msg {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;

    .empty-txt {
      color: #212121;
      line-height: 22px;
      text-align: center;
      margin-bottom: 11px;
    }

    .empty-image {
      display: block;
      width: 160px;
      height: 160px;
      margin: 0 auto;
      background: url(https://res.17track.net/global-v2/imgs/illustration/160x160/no-msg.png);
    }
  }

  .network-error {
    height: 100%;
    text-align: center;
    padding: 20px 0;

    .error-txt {
      color: #212121;
      line-height: 22px;
      text-align: center;
      margin-bottom: 11px;
    }

    .error-image {
      display: block;
      width: 160px;
      height: 160px;
      margin: 0 auto;
      background: url(https://res.17track.net/global-v2/imgs/illustration/160x160/network-error.png);
    }

    .refresh-btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      color: #fff;
      background-color: #4caf50;
      border-color: #4caf50;
      padding: 0 15px;
      border-radius: 3px;

      &:hover {
        background-color: #3d8b40;
        border-color: #39843c;
      }
    }
  }

  .dropdown-menu-footer {
    height: 54px;
    line-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border-top: 1px solid #e0e0e0;

    a {
      color: #003a9b;
    }
  }
}

@media screen and (max-width: 767px) {
  .custom-dropdown-menu {
    top: 100%;
    right: 0;
    left: 0;
    width: auto;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
