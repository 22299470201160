.copyright {
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #9e9e9e;
  font-size: 14px;
  padding: 12px 16px;
  border-top: 1px solid #e0e0e0;
  background: var(--bs-color-bg);

  a {
    color: #9e9e9e;
  }
}
