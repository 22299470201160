.navbar-left,
.navbar-center,
.navbar-right {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  .m-size-3 {
    margin: 0 16px 0 0;
  }
}

.navbar-left {
  margin-right: 24px;
}
