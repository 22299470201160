.side-bar {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 60px;

  .sidebar-item {
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    &.hidden {
      display: none;
    }

    &:hover {
      background-color: var(--primary-color-hover);
    }

    &:hover {
      .share {
        display: flex;
      }
    }

    .share {
      position: absolute;
      right: 36px;
      display: none;

      .link {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #757575;
        background-color: #eee;
        text-decoration: none;

        &:first-child {
          border-right: 1px solid #e3e3e3;
        }

        &.fa-share-25:hover {
          color: #fff;
          background-color: #ffcd00;
        }

        &.fa-share-22:hover {
          color: #fff;
          background-color: #e6162d;
        }

        &.fa-share-15:hover {
          color: #fff;
          background-color: #4d75a3;
        }

        &.fa-share-14:hover {
          color: #fff;
          background-color: #0077b5;
        }

        &.fa-share-12:hover {
          color: #fff;
          background-color: #1da1f2;
        }

        &.fa-share-11:hover {
          color: #fff;
          background-color: #365899;
        }
      }
    }
  }
}
