// 定义icon的基础信息方便后续更改
$nav-icon-width: 24px;
$nav-icon-height: 16px;
$span-height: 2px;
$bg-color: #1e1e1e;
$transition-duration: 0.5s;
$span-transition-duration: 0.25s;

@mixin transform($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin transition($duration) {
  -webkit-transition: $duration ease-in-out;
  -moz-transition: $duration ease-in-out;
  -o-transition: $duration ease-in-out;
  transition: $duration ease-in-out;
}

/* Icon 1 */
.hamburger {
  padding: 8px 4px;
  display: none;
}

.nav-icon {
  height: $nav-icon-height;
  width: $nav-icon-width;
  position: relative;
  @include transform(0deg);
  @include transition($transition-duration);
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: $span-height;
    width: $nav-icon-width;
    background: $bg-color;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(0deg);
    @include transition($span-transition-duration);
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2) {
    top: calc($nav-icon-height / 3 * 1);
  }

  span:nth-child(3) {
    top: calc($nav-icon-height / 3 * 2);
  }

  &.open {
    span:nth-child(1) {
      top: calc($nav-icon-height / 3);
      @include transform(135deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      top: calc($nav-icon-height / 3);
      @include transform(-135deg);
    }
  }
}

@media (max-width: 1199px) {
  .hamburger {
    display: block;
  }
}

@media (max-width: 768px) {
  .hamburger {
    padding: 6px 3px;
  }

  .nav-icon {
    height: 12px;
    width: 18px;

    span {
      width: 18px;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2) {
      top: 6px;
    }

    span:nth-child(3) {
      top: 12px;
    }

    &.open {
      span:nth-child(1) {
        top: 6px;
      }

      span:nth-child(3) {
        top: 6px;
      }
    }
  }
}
