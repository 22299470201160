// 下箭头
.arrowDown {
  use {
    will-change: transform;
    transition-duration: 0.42s;
    transform-origin: center;
  }
  &.arrowDownRotate {
    use {
      transform: rotate(180deg);
    }
  }
}

// 右箭头
.arrowRight {
  display: flex;
  align-items: center;
  .arrow {
    display: inline-block;
    opacity: 0 !important;
    padding-left: 4px;
    transform: translateX(0px) !important;
    transition: 0.2s !important;
  }
  &:hover {
    color: var(--primary-color-hover);
    .arrow {
      transform: translateX(4px) !important;
      opacity: 1 !important;
    }
  }
}

.arrowRightBox {
  // 头部内容通用
  .font-head {
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.2px;
    margin-bottom: 4px;
  }

  // 描述内容通用
  .font-desc {
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
  }

  &:hover {
    text-decoration: none;
    .font-head {
      color: #003a9b;
    }
    .arrowRight {
      &::after {
        transform: translateX(4px);
      }
    }
  }
}

@media (max-width: 1199px) {
  .arrowRightBox {
    .font-head {
      font-size: 16px;
    }
  }
}
