// 用于非tab切换的效果
@keyframes hoverMove {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

// 用于切换不同tab的交互
@keyframes hoverTabMove {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0.2;
  }

  70% {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
}

.navbar {
  position: sticky;
  height: var(--yq-navbar-height);
  padding: 0;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #212121;
  background-color: #fff;
  z-index: 101;
  border-bottom: 1px solid color(display-p3 0.9608 0.9608 0.9608);
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-container {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1440px;
    // 菜单导航整体布局
    .navbar-center {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      .m-size-3 {
        margin: 0 16px 0 0;
      }
    }

    // 菜单导航item
    .nav-item {
      padding: 0;
      // height: var(--yq-navbar-height);
      font-weight: 600;
      display: flex;
      align-items: center;

      .mt-item {
        margin-top: 32px;
      }
    }

    .item-img-box {
      p,
      a {
        margin-top: 24px;
      }
    }
  }
}

// 通用样式类

// 通用按钮
.btn {
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  border-radius: 12px;
  padding: 8px 16px;
  text-decoration: none;
}

// link格式按钮
.btn-link {
  display: inline-block;
  width: 100%;
  padding: 24px 8px 24px 16px;
  color: #212121;
  text-decoration: none;
  &:hover {
    color: #003a9b;
    text-decoration: none;
  }
}

.open > a,
.open > button {
  color: #616161;
  background-color: rgba(238, 238, 238, 0.6);
}

// 移动端样式

@media (min-width: 1240px) and (max-width: 1439px) {
  .navbar {
    .navbar-container {
      padding: 0 50px;
    }
  }
}
@media (max-width: 1199px) {
  .navbar {
    .expanded {
      // 会根据下拉控件展示移动端菜单
      display: flex !important;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      position: absolute;
      top: var(--yq-navbar-height);
      left: 0;
      z-index: 100;
      background-color: #fff;
      height: calc(100vh - var(--yq-navbar-height));
      overflow-y: auto;
      margin: 0;
      .navbar-center {
        flex-direction: column;
        text-align: center;
      }
      .nav-item {
        height: auto;
        width: 100%;
        flex-direction: column;
        text-align: left;

        & > a {
          padding: 16px 24px;
        }

        .btn-link {
          padding: 16px 24px;
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    height: var(--yq-navbar-md-height);
    .expanded {
      top: var(--yq-navbar-md-height);
      height: calc(100vh - var(--yq-navbar-md-height));
    }
  }
  .xsStatic {
    position: static !important;
  }
}

@media (max-width: 390px) {
  .xsStatic {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .navbar {
    .md-flex-show {
      display: flex !important;
      width: 100%;
    }
    .expanded {
      .md-flex-show {
        display: none !important;
      }
    }
  }
}
